import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientsAPI } from "../../app/Services/axios";

export interface ContactData {
  customerContactName: string;
  customerContactLastName: string;
  customerContactTel: string;
  customerContactEmail: string;
}

export interface Tag {
  tagId: string;
  tagName: string;
}
export interface VehicleFormData {
  endConsumerVehicleModelCode: string,
  endConsumerVehicleType: string,
  endConsumerVehicleYear: number,
  endConsumerVehicleBrand: string,
  endConsumerVehicleModel: string,
  endConsumerVehicleFuelType: string,
  isCustomBrand: boolean,
  isCustomModel: boolean,
  endConsumerVehicleLicensePlate: string,
  endConsumerVehicleSeriesNo: string,
  endConsumerVehicleMainPurposeCode: string,
  endConsumerVehicleKm: string
}

export interface AppointmentFormData {
  service: string;
  date: string;
  vehicle: string;
}
export interface CustomerListFilter {
  lastServiceDate: string,
  vehicleType: string,
  vehicleBrand: string,
  vehicleLicenceTermination: string,
  vehicleYear: string,
  vehicleYearStart: string,
  services: string,
  tags: string[],
  order: string,
  filtersApplied: boolean;
}

export interface AdditionalContactForm {
  contactName: string;
  contactLastName: string;
  contactTel: string;
  contactEmail: string;
}

export interface CustomerFormData {
  customerId?: string;
  customerName: string;
  customerLastName: string;
  customerTel: string;
  customerEmail: string;
  contacts: ContactData[];
  customerZipCode: string;
  customerDateOfBirth: string;
  customerSex: string;
  customerAddress: string;
  customerNotes: string;
  customerReceptorName: string;
  customerRFC: string;
  customerTaxRegimen: string;
  customerCFDI: string;
  customerTaxZipCode: string;
  customerTaxEmail: string;
  customerTags: Tag[];
  customerVehicles: VehicleFormData[];
  appointmentHistory: AppointmentFormData[];
  appointments: any[];
}

interface CustomerFormState {
  currentWorkshopTags: Tag[],
  customerFormData: CustomerFormData,
  vehicleFormData: VehicleFormData,
  customerListFilter: CustomerListFilter,
  additionalContactForm: AdditionalContactForm,
}

let initialState: CustomerFormState = {
  currentWorkshopTags: [],
  customerFormData: {
    customerName: "",
    customerLastName: "",
    customerTel: "",
    customerEmail: "",
    contacts: [],
    customerZipCode: "",
    customerDateOfBirth: "",
    customerSex: "",
    customerAddress: "",
    customerNotes: "",
    customerReceptorName: "",
    customerRFC: "",
    customerTaxRegimen: "",
    customerCFDI: "",
    customerTaxZipCode: "",
    customerTaxEmail: "",
    customerTags: [],
    customerVehicles: [],
    appointmentHistory: [],
    appointments: [],
  },
  vehicleFormData: {
    endConsumerVehicleModelCode: "",
    endConsumerVehicleType: "",
    endConsumerVehicleYear: 0,
    endConsumerVehicleBrand: "",
    endConsumerVehicleModel: "",
    endConsumerVehicleFuelType: "",
    isCustomBrand: false,
    isCustomModel: false,
    endConsumerVehicleLicensePlate: "",
    endConsumerVehicleSeriesNo: "",
    endConsumerVehicleMainPurposeCode: "",
    endConsumerVehicleKm: ""
  },
  customerListFilter: {
    lastServiceDate: "",
    vehicleType: "",
    vehicleBrand: "",
    vehicleLicenceTermination: "",
    vehicleYear: "",
    vehicleYearStart: "",
    services: "",
    tags: [],
    filtersApplied: false,
    order: "ASCE",
  },
  additionalContactForm : {
    contactName: "",
    contactLastName: "",
    contactTel: "",
    contactEmail: "",
  }
}

export const fetchWorkshopTags = createAsyncThunk(
  "newCustomerForm/fetchWorkshopTags",
  async (workshopId: string) => {
    const workshopTags = await ClientsAPI.getTags(workshopId);
    return workshopTags.data;
  }
);

export const addNewWorkshopTag = createAsyncThunk(
  "newCustomerForm/addNewWorkshopTag",
  async (args: { workshopId: string, tagName: string }) => {
    const workshopTags = await ClientsAPI.addTagToWS(args.workshopId, args.tagName);
    return workshopTags.data;
  }
);

const newCustomerFormSlice = createSlice({
  name: "newCustomerForm",
  initialState,
  reducers: {
    resetCustomerFormData (state) {
      state.customerFormData = {
        customerName: "",
        customerLastName: "",
        customerTel: "",
        customerEmail: "",
        contacts: [],
        customerZipCode: "",
        customerDateOfBirth: "",
        customerSex: "",
        customerAddress: "",
        customerNotes: "",
        customerReceptorName: "",
        customerRFC: "",
        customerTaxRegimen: "",
        customerCFDI: "",
        customerTaxZipCode: "",
        customerTaxEmail: "",
        customerTags: [],
        customerVehicles: [],
        appointmentHistory: [],
        appointments: [],
      };
    },
    resetVehicleFormData (state) {
      state.vehicleFormData = {
        endConsumerVehicleModelCode: "",
        endConsumerVehicleType: "",
        endConsumerVehicleYear: 0,
        endConsumerVehicleBrand: "",
        endConsumerVehicleModel: "",
        endConsumerVehicleFuelType: "",
        isCustomBrand: false,
        isCustomModel: false,
        endConsumerVehicleLicensePlate: "",
        endConsumerVehicleSeriesNo: "",
        endConsumerVehicleMainPurposeCode: "",
        endConsumerVehicleKm: ""
      };
    },
    resetCustomerListFilter (state) {
      state.customerListFilter = {
        lastServiceDate: "",
        vehicleType: "",
        vehicleBrand: "",
        vehicleLicenceTermination: "",
        vehicleYear: "",
        vehicleYearStart: "",
        services: "",
        tags: [],
        filtersApplied: false,
        order: state.customerListFilter.order,
      };
    },
    resetAdditionalContactFormData (state) {
      state.additionalContactForm = {
        contactName: "",
        contactLastName: "",
        contactTel: "",
        contactEmail: "",
      };
    },
    setCustomerFormData(state, action) {
      state.customerFormData = action.payload;
    },
    updateCustomerFormData(state, action) {
      state.customerFormData = { ...state.customerFormData, ...action.payload };
    },
    setVehicleFormData(state, action) {
      state.vehicleFormData = action.payload;
    },
    updateVehicleFormData(state, action) {
      state.vehicleFormData = { ...state.vehicleFormData, ...action.payload };
    },
    setCustomerListFilterData(state, action) {
      state.customerListFilter = action.payload;
    },
    updateCustomerListFilterData(state, action) {
      state.customerListFilter = { ...state.customerListFilter, ...action.payload };
    },
    setAdditionalContactFormData(state, action) {
      state.additionalContactForm = action.payload;
    },
    updateAdditionalContactFormData(state, action) {
      state.additionalContactForm = { ...state.additionalContactForm, ...action.payload };
    },
    setCustomerTags(state, action) {
      state.customerFormData = { ...state.customerFormData, customerTags: action.payload };
    },
    addAdditionalCustomerToData(state, action) {
      state.customerFormData = { ...state.customerFormData, contacts: [ ...state.customerFormData.contacts, action.payload ]};
    },
    addCustomerVehicleToData(state, action) {
      state.customerFormData = { ...state.customerFormData, customerVehicles: [ ...state.customerFormData.customerVehicles, action.payload ]};
    },
    addCustomerTagToData(state, action) {
      state.customerFormData = { ...state.customerFormData, customerTags: [ ...state.customerFormData.customerTags, action.payload ]};
    },
    addAppointmentToHistoryData(state, action) {
      state.customerFormData = { ...state.customerFormData, appointmentHistory: [ ...state.customerFormData.appointmentHistory, action.payload ]};
    },
    setCurrentWorkshopTags(state, action) {
      state.currentWorkshopTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkshopTags.fulfilled, (state, { payload }) => {
      state.currentWorkshopTags = payload
    });

    builder.addCase(fetchWorkshopTags.pending, (state, { payload }) => {
      //
    });

    builder.addCase(fetchWorkshopTags.rejected, (state, { payload }) => {
      //
    });
    builder.addCase(addNewWorkshopTag.fulfilled, (state, { payload }) => {
      state.currentWorkshopTags = [...state.currentWorkshopTags, payload];
    });

    builder.addCase(addNewWorkshopTag.pending, (state, { payload }) => {
      //
    });

    builder.addCase(addNewWorkshopTag.rejected, (state, { payload }) => {
      //
    });
  },
});

export const {
  addAdditionalCustomerToData,
  addCustomerVehicleToData,
  addCustomerTagToData,
  addAppointmentToHistoryData,
  setCustomerTags,
  setCustomerFormData,
  updateCustomerFormData,
  setVehicleFormData,
  updateVehicleFormData,
  setCustomerListFilterData,
  updateCustomerListFilterData,
  setAdditionalContactFormData,
  updateAdditionalContactFormData,
  resetCustomerFormData,
  resetVehicleFormData,
  resetCustomerListFilter,
  resetAdditionalContactFormData
} = newCustomerFormSlice.actions;

export default newCustomerFormSlice.reducer;
