import { createSlice } from "@reduxjs/toolkit";

interface WorkshopData {
    id: string;
    WorkshopIdentificator: string;
    serviceTypes: any[];
}

interface WorkshopFormState {
    originalWorkshopData : WorkshopData,
    workshopData : WorkshopData
    location: {
      zipCode: string,
      city: string,
      municipality: string,
      neighborhood: string,
      address: string,
      latitude: 0,
      longitude: 0,
      country: string,
      WorkshopStateProvince: string,
    }
}

let emptyWorkshopData = {
  id: "",
  name: "",
  typeCodes: [],
  email: "",
  workshopPhoneNumber: "",
  customerPhoneNumber: "",
  WorkshopIdentificator: "",
  workshopPhotos: [],
  workingHours: [],
  additionalInfo: [],
  workshopBrands: [],
  carTypes: [],
  serviceTypes: [],
  packages: [],
  workshopCategoryId: 0,
  about: "",
  mondayToFridayDayHours: {
    startingTime: undefined,
    endingTime: undefined,
  },
  everyDayHours: {
    startingTime: undefined,
    endingTime: undefined,
  },
  useSameHourMondayToFriday: undefined,
  WorkshopRFC: "",
  WorkshopSocialReason: "",
}

let initialState: WorkshopFormState = {
    originalWorkshopData : emptyWorkshopData,
    workshopData: emptyWorkshopData,
    location: {
      zipCode: "",
      city: "",
      municipality: "",
      neighborhood: "",
      address: "",
      latitude: 0,
      longitude: 0,
      country: "",
      WorkshopStateProvince: ""
    },
}

const workshopFormSlice = createSlice({
  name: "workshopForm",
  initialState,
  reducers: {
    resetData (state) {
      state.workshopData = emptyWorkshopData;
    },
    setData(state, action) {
      state.workshopData = action.payload;
      state.originalWorkshopData = action.payload;
    },
    setLocation(state, action) {
      state.location = action.payload;
    },
  },
});
export const { setData, resetData, setLocation } = workshopFormSlice.actions;
export default workshopFormSlice.reducer;
