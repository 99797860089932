import { createSlice } from "@reduxjs/toolkit";

interface NotificationsState {
  notificationNumber : number,
}

let initialState: NotificationsState = {
  notificationNumber : 0,
}

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationNumber(state, action) {
      state.notificationNumber = action.payload;
    },
  },
});
export const { setNotificationNumber} = notificationSlice.actions;
export default notificationSlice.reducer;
