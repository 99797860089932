import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IPFilterWorkshop, WorkshopPublicBasicInfo } from "shared";
import { WorkshopAPI } from "../../app/Services/axios";

type FilterHelperSlice = {
  workshopsFiltered: WorkshopPublicBasicInfo[];
  coordinates: string | undefined;
  status: "loading" | "succeeded" | "failed";
  error?: string;
};

let initialState: FilterHelperSlice = {
  workshopsFiltered: [],
  coordinates: undefined,
  status: "loading",
  error: undefined,
};

interface ISearchWorshopFiltered {
  language?: string;
  filters: IPFilterWorkshop;
}

export const fetchWorkshopFiltered = createAsyncThunk(
  "filters/fetchWorkshopFiltered",
  async ({ language, filters }: ISearchWorshopFiltered) => {
    let response = await WorkshopAPI.search(filters);
    return response.data;
  }
);

const WorkshopFilteredSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    clearWorkshopFiltered(state) {
      state.workshopsFiltered = [];
      state.error = undefined;
    },
    setCoordinates(state, action) {
      state.coordinates = action.payload;
    },
    setWorkshopFiltered(state, action) {
      state.workshopsFiltered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkshopFiltered.fulfilled, (state, { payload }) => {
      state.workshopsFiltered = payload;
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(
      fetchWorkshopFiltered.pending,
      (state, { payload, meta }) => {
        state.status = "loading";
        state.error = undefined;
      }
    );

    builder.addCase(
      fetchWorkshopFiltered.rejected,
      (state, { payload, error }) => {
        state.status = "failed";
        state.error = error.message;
      }
    );
  },
});

export const {
  clearWorkshopFiltered,
  setCoordinates,
  setWorkshopFiltered,
} = WorkshopFilteredSlice.actions;
export default WorkshopFilteredSlice.reducer;
