import { RootState } from "../../stores";

export const getWorkshops = (state: RootState) => state.workshop.workshops;

export const getWorkshopSelected = (state: RootState) => state.workshop.index;
export const getCurrentWorkshop = (state: RootState) => state.workshop.workshops[state.workshop.index];

export const getWorkshopTypes = (state: RootState) =>
  state.catalog.workshopTypes;

export const getVehicleBrands = (state: RootState) =>
  state.catalog.vehicleBrand;
export const getVehicleModels = (state: RootState) =>
  state.catalog.vehicleModel;

export const getLoading = (state: RootState) => state.workshop.loading;
export const getError = (state: RootState) => state.workshop.error;
export const getAction = (state: RootState) => state.workshop.action;
export const getRecentlyAddedIndex = (state: RootState) => state.workshop.recentlyAddedIndex;

export const getWorkshopServices = (state: RootState) => state.workshop.services;
export const getStagedWorkshopServices = (state: RootState) => state.workshop.stagedServices;
export const getStagedWorkshopServicesForUpdate = (state: RootState) => state.workshop.stagedServices.filter((stagedServices) => !!stagedServices.service.workshopServiceId);
export const getStagedWorkshopServicesForCreate = (state: RootState) => state.workshop.stagedServices.find((stagedServices) => !!stagedServices.service.workshopServiceId === false);

export const getUsers = (state: RootState) => state.user.users;
export const getUsersLoading = (state: RootState) => state.user.loading;
export const getUserError = (state: RootState) => state.user.error;
export const getUserAction = (state: RootState) => state.user.action;

export const getUser = (state: RootState) => state.user.user;

export const getServicesByWorkshopType = (state: RootState) =>
  state.catalog.ServicesByWorkshopType;

export const getServices = (state: RootState) => state.catalog.Services;
export const getRoles = (state: RootState) => state.catalog.Roles;

export const getLoadingCatalog = (state: RootState) => state.catalog.loading;
export const getErrorCatalog = (state: RootState) => state.catalog.error;
export const getDays = (state: RootState) => state.catalog.Days;

export const getSasToken = (state: RootState) => state.catalog.sasToken;


export const getServicesCategories = (state: RootState) => state.catalogs.servicesCategories;
export const getPackagesCategories = (state: RootState) => state.catalogs.packagesCategories;

export const getVehicleZones = (state: RootState) => state.catalogs.vehicleZones;
export const getServiceCases = (state: RootState) => state.catalogs.serviceCases;
/*************************APPOINTMENTS***************************/

export const getAppointments = (state: RootState) =>
  state.appointment.appointments;
export const getAppointment = (state: RootState) =>
  state.appointment.appointment;
export const getAppointmentLoading = (state: RootState) =>
  state.appointment.loading;
export const getAppointmentError = (state: RootState) =>
  state.appointment.error;
export const getAppointmentStatus = (state: RootState) =>
  state.catalog.appointmentStatus;

/****************************PUBLIC CATALOGS************************************/

export const getPublicWorkshopType = (state: RootState) =>
  state.publicCatalog.workshopTypes;

export const getPublicWorkshopServices = (state: RootState) =>
  state.publicCatalog.services;

/****************************PUBLIC WORKSHOP FILTERED************************************/

export const getWorkshopFiltered = (state: RootState) =>
  state.workshopFiltered.workshopsFiltered;

export const getCoordinates = (state: RootState) =>
  state.workshopFiltered.coordinates;

export const getLoadingWorshopFiltered = (state: RootState) =>
  state.workshopFiltered.status;

export const getQuizSections = (state: RootState) => state.catalog.quizSections;

export const getQuizChecklist = (state: RootState) =>
  state.catalog.quizChecklist;

/****************************PROMO CODES SELECTOR************************************/

export const getRewardData = (state: RootState) => state.promoCodes.rewards;
export const getBalanceData = (state: RootState) => state.promoCodes.balance;


/****************************NAVIGATION SELECTOR************************************/
export const getCurrentSection = (state: RootState) => state.navigation.currentSection;
export const getRatings = (state: RootState) => state.navigation.ratings;
export const getExpandedMenu = (state: RootState) => state.navigation.expandedMenu;

/****************************WORKSHOP FORM SELECTOR************************************/

export const getWorkshopFormData = (state: RootState) => state.workshopForm.workshopData;
export const getWorkshopFormOriginalData = (state: RootState) => state.workshopForm.originalWorkshopData;
export const getWorkshopFormLocation = (state: RootState) => state.workshopForm.location;

/****************************NOTIFICATIONS SELECTOR************************************/

export const getNotificationsNumber = (state: RootState) => state.notification.notificationNumber;

/****************************NOTIFICATIONS SELECTOR************************************/

export const getCustomerFormData = (state: RootState) => state.customer.customerFormData;
export const getVehicleFormData = (state: RootState) => state.customer.vehicleFormData;
export const getCustomerListFilterData = (state: RootState) => state.customer.customerListFilter;
export const getAdditionalContactFormData = (state: RootState) => state.customer.additionalContactForm;
export const getCurrentWorkshopTags = (state: RootState) => state.customer.currentWorkshopTags;

