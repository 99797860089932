import { combineReducers } from "redux";
import {
  workshopReducer,
  userReducer,
  catalogReducer,
  appointmentReducer,
  catalogsReducer,
  promoCodeReducer,
} from "shared";

import { default as publicCatalogReducer } from "../Slice/PublicCatalogSlice";
import { default as WorkshopFilterSlice } from "../Slice/WorkshopFilterSlice";
import { default as NavigationSlice } from "../Slice/NavigationSlice";
import { default as WorkshopFormSlice } from "../Slice/WorkshopFormSlice";
import { default as NotificationsSlice } from "../Slice/NotificationsSlice";
import NewCustomerFormSlice from "../Slice/NewCustomerFormSlice";

const rootReducer = combineReducers({
  workshop: workshopReducer,
  workshopForm: WorkshopFormSlice,
  notification: NotificationsSlice,
  customer: NewCustomerFormSlice,
  user: userReducer,
  catalog: catalogReducer,
  appointment: appointmentReducer,
  catalogs: catalogsReducer,
  publicCatalog: publicCatalogReducer,
  workshopFiltered: WorkshopFilterSlice,
  promoCodes: promoCodeReducer,
  navigation: NavigationSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
