import { createSlice } from "@reduxjs/toolkit";

interface IRateAverage {
  rating: string;
  numberOfFeedbacks: string;
  feedbackCategoryCode: "FACILI" | "FINDELTIM" | "KINSTA" | "PUNCTU" | "QUAPRIRAT" | "QUASERVEH";
  feedbackCategoryName: string;
  isVisible: boolean | null;
}
type NavigationState = {
  currentSection: "" | "ratings" | string;
  ratings: {
    workshopName: string;
    total: string;
    averages: IRateAverage[];
  } | null;
  expandedMenu: boolean;
};

const initialState: NavigationState = {
  currentSection: "",
  ratings: {
    workshopName: "",
    total: "",
    averages: []
  },
  expandedMenu: false
};

const NavigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setCurrentSection(state, action) {
      state.currentSection = action.payload;
      if (action.payload === "ratings") state.ratings = null;
    },
    setRatings(state, action : { payload : {
      workshopName: string;
      total: string;
      averages: IRateAverage[];
    } }) {
      // state.currentSection = "ratings";
      state.ratings = {
        workshopName: action.payload.workshopName,
        total: action.payload.total,
        averages: action.payload.averages
      }
    },
    toogleExpandedMenu(state, action) {
      if (action.payload !== undefined) { 
        state.expandedMenu = action.payload;
      } else {
        state.expandedMenu = !state.expandedMenu;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { setCurrentSection, setRatings, toogleExpandedMenu } = NavigationSlice.actions;
export default NavigationSlice.reducer;
