import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceCategory, WorkshopService, WorkshopType } from "shared";
import { CatalogAPI } from "../../app/Services/axios";

type CatalogsState = {
  currentSection: string;
  workshopTypes: WorkshopType[] | undefined;
  //   days: Day[];
  services: WorkshopService[];
  serviceCategories: ServiceCategory[];
  status: "loading" | "succeeded" | "failed";
  error?: string;
};

let initialState: CatalogsState = {
  currentSection: "",
  workshopTypes: [],
  //   days: [],
  services: [],
  serviceCategories: [],
  status: "loading",
  error: undefined,
};

export const fetchPublicCatalogs = createAsyncThunk(
  "catalogs/fetchPublicCatalogs",
  async (language: string) => {
    const [
      typesResponse,
      //   daysResponse,
      servicesResponse,
    ] = await Promise.all([
      CatalogAPI.getWorkshopTypes(language),
      //   CatalogAPI.getDays(),
      CatalogAPI.getServices(language),
    ] as const);

    return {
      types: typesResponse.data,
      //   days: daysResponse.data,
      services: servicesResponse.data,
    };
  }
);

const PublicCatalogSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    setWorkshopTypes(state, action) {
      state.workshopTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPublicCatalogs.fulfilled, (state, { payload }) => {
      const { types, services } = payload;
      state.workshopTypes = types;
      state.services = services.sort((a, b) => {
        if (a.serviceName > b.serviceName) return 1;
        if (a.serviceName < b.serviceName) return -1;
        return 0;
      });
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(fetchPublicCatalogs.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined;
    });

    builder.addCase(fetchPublicCatalogs.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs";
    });
  },
});

export const { setWorkshopTypes } = PublicCatalogSlice.actions;
export default PublicCatalogSlice.reducer;
